@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.not_found {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-height: 528px;
  padding: 48px;
  text-align: center;

  @include breakpoint($lg) {
    min-height: 928px;
    padding: 120px 0 80px;
  }
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @include breakpoint($lg) {
    font-size: 72px;
    line-height: 80px;
  }
}

.btn {
  display: flex;
  justify-content: center;
  padding-top: 32px;

  @include breakpoint($lg) {
    padding-top: 117px;
  }
}

.main_img {
  width: 100%;
  margin-top: 48px;

  @include breakpoint($md) {
    width: 629px;
    height: 266px;
    margin-top: 76px;
  }
}
